<template>
  <header id="header">
    <div class="header-container">
      <img :src="logo" alt="realsqm" @click="navigateHome" style="cursor:pointer;">
      <div class="sh-dropdown-container">
        <button class="sh-burger-menu" ref="menu" @click="toggleMenu">
          <img :src="menuClose" alt="menuClose">
        </button>
      </div>
    </div>
  </header>
  <div class="sh-overlay" v-if="isMenuOpen" @click="toggleMenu"></div>
  <transition name="slide-left">
          <menu-sidebar v-model:isVisible="isMenuOpen" />
        </transition>
</template>

<script>

import burgerIconOpen from '@/assets/menu_open.svg';
import burgerIconClose from '@/assets/menu_close.svg';
import realSqmIcon from '@/assets/estad.svg';
import MenuSidebar from './MenuSidebar.vue';

export default {

  components: {
    'menu-sidebar': MenuSidebar
  },

  name: 'SiteHeader',

  data() {
    return {
      menuOpen: burgerIconOpen,
      menuClose: burgerIconClose,
      logo: realSqmIcon,
      isMenuOpen: false
    };
  },

  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

    handleClickOutside(event) {
      if (!this.$refs.menu.contains(event.target)) {
        this.isMenuOpen = false;
      }
    },

    navigateHome() {
      this.$router.push('/');
    }
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
}
</script>

<style>

#header {
  display: flex;
  justify-content: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 60px;
  background-color: white;
  z-index: 1;
}

.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px 30px 8px 30px;
}

header img {
  width: 100px;
}

.menu-line {
  width: 100%;
  display: flex;
  padding-bottom: 40px;
  flex-direction: row;
  justify-content: right;
}

.sh-burger-menu {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  right: 0;
}

.sh-burger-menu img {
  width: 30px;
  height: auto;
}

.sh-dropdown-container {
  position: relative;
  display: flex;
  align-items: center;
}

.sh-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

@media (max-width: 768px) {
  header img {
    width: 70px;
  }
}
</style>
