import { reactive } from 'vue';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Reactive state to store user data
const state = reactive({
  user: null,
});

// Initialize authentication state
const auth = getAuth();
onAuthStateChanged(auth, user => {
  state.user = user; // Update reactive state when user's auth state changes
});

// Export the reactive state and any methods to interact with it
export default state;
