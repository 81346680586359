<template>
  <div class="map-container">
    <div id="map"></div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import axios from 'axios';

export default {
  name: 'MapboxContainer',

  data() {
    return {
      markerClicked: false,
      map: null,
      features: [],
      mapCenter: [115.227814, -8.703379], // Default map center
      mapInitialized: false,
      mapInitializationPromise: null, // Initialize as null
      mapZoom: this.isMobile() ? 10 : 11.46,
    };
  },

  methods: {
    isMobile() {
      return window.innerWidth <= 768;
    },

    updateMapZoom() {
      this.mapZoom = this.isMobile() ? 8 : 11.46;
    },

    handleClickOutside(event) {
      if (this.$refs.currencyDropdownButton && !this.$refs.currencyDropdownButton.contains(event.target)) {
        this.isCurrencyDropdownOpen = false;
      }
    },

    highlightClickedArea(feature) {
      this.markerClicked = true;

      const [lng, lat] = feature.geometry.coordinates[0][0];
      const areaCenter = [lng, lat];

      if (this.map) {
        
        if (!this.$store.state.lockZoom) {
          this.map.flyTo({
            center: areaCenter,
            zoom: this.isMobile() ? 12 : 13,
            essential: true,
          });
        }

        this.$store.commit('setMapZoom', false);

        // Highlight the clicked extrusion
        const identifier = feature.properties.name;
        this.map.setPaintProperty('sqmdata', 'fill-extrusion-color', [
          'case',
          ['==', ['get', 'name'], identifier], '#7D0A0A', // Highlight color
          // Default color based on price_per_sqm_eur
          ['interpolate', ['linear'], ['get', 'price_per_sqm_eur'],
            800, '#0C0C0C',
            1000, '#164863',
            1300, '#427D9D',
            1500, '#9BBEC8',
            3000, '#DDF2FD',
          ],
        ]);
      }
    },

    async initializeMap() { // Add async here
  if (this.mapInitializationPromise) {
    return this.mapInitializationPromise;
  }

  mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;

  const bounds = [
    [114.316605, -8.899764],
    [115.852038, -7.972213],
  ];

  this.mapInitializationPromise = new Promise((resolve, reject) => {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: process.env.VUE_APP_MAPBOX_MAP_STYLE,
      center: this.mapCenter,
      zoom: this.mapZoom,
      bearing: -9.60,
      pitch: 51.00,
      maxBounds: bounds,
    });

    this.map.on('load', async () => {
      try {
        // Fetch the GeoJSON data from the API
        const response = await axios.get('https://us-central1-realsqm-8ca89.cloudfunctions.net/api/geojson');
        const regions = response.data;

        this.features = regions.features;

        this.map.addSource('sqmregion', {
          'type': 'geojson',
          'data': regions,
        });

        this.map.addLayer({
          'id': 'sqmdata',
          'maxzoom': this.isMobile() ? 12 : 13,
          'type': 'fill-extrusion',
          'source': 'sqmregion',
          'paint': {
            'fill-extrusion-height': 0,
            'fill-extrusion-base': 0,
            'fill-extrusion-vertical-scale': 2,
            'fill-extrusion-color': [
              'interpolate',
              ['linear'],
              ['get', 'price_per_sqm_eur'],
              800, '#0C0C0C',
              1000, '#164863',
              1300, '#427D9D',
              1500, '#9BBEC8',
              3000, '#DDF2FD',
            ],
            'fill-extrusion-opacity': 0.7,
          },
        });

        let maxPricePerSqm = 4000;
        this.animateExtrusion(maxPricePerSqm);
        resolve();

        // Set up click event listener
        this.map.on('click', (e) => {
          const features = this.map.queryRenderedFeatures(e.point, { layers: ['sqmdata'] });

          if (features.length) {
            const feature = features[0];

            // Save data to vue store
            this.$store.commit('setSelectedArea', feature);

            this.highlightClickedArea(feature);
          }
        });

      } catch (error) {
        console.error('Error fetching GeoJSON data:', error);
        reject(error);
      }
    });

    this.map.on('error', (error) => {
      console.error('Mapbox error:', error);
      reject(error);
    });

    resolve(); // Resolve the promise after setting up the map
  });

  return this.mapInitializationPromise;
},

animateExtrusion(targetHeight) {
      let currentHeight = 0;
      const animationStep = () => {
        currentHeight += (targetHeight - currentHeight) * 0.1;
        if (this.map) {
          this.map.setPaintProperty('sqmdata', 'fill-extrusion-height', [
            'interpolate',
            ['linear'],
            ['get', 'price_per_sqm_eur'],
            0, 0,
            targetHeight, currentHeight,
          ]);
        }

        if (currentHeight < targetHeight) {
          requestAnimationFrame(animationStep);
        }
      };

      animationStep();
    },

  },

  watch: {
    '$store.state.selectedArea': {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.properties) {
          if (this.mapInitialized) {
            this.highlightClickedArea(newVal);
          } else {
            this.mapInitializationPromise.then(() => this.highlightClickedArea(newVal));
          }
        }
      },
    },

    '$store.state.mapZoom': {
      immediate: true,
      handler(newVal, oldVal) {
        if (oldVal !== undefined) {
          console.log('oldval: '+oldVal)
          if(newVal === true) {
            this.mapInitializationPromise.then(() => {
              this.map.flyTo({
                center: this.mapCenter,
                zoom: this.isMobile() ? 10 : 11.46,
                essential: true,
              }); 
          }).catch((error) => {
            console.error('Error flying to new zoom level:', error);
          });

          this.$store.commit('setMapZoom', false);
          }
        }
      },
    },
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.updateMapZoom);
    document.removeEventListener('click', this.handleClickOutside);
  },
  
  mounted() {
    this.initializeMap();
    this.$store.commit('setMapZoom', false);
    window.addEventListener('resize', this.updateMapZoom);
    document.addEventListener('click', this.handleClickOutside);
  },

  computed: {
    lockZoom() {
      return this.$store.state.lockZoom;
    },
  }
};
</script>


<style>

.map-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  position: relative;
  top: 60;
  bottom: 60;
}

#map {
  flex-grow: 1;
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.modal-overlay {
  display: flex;
  flex-direction: column;
  width: 500px;
  position: absolute;
  gap: 20px;
  right: 0px;
  bottom: 60;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
}

.overlay-container {
  height: auto;
  color: #4b4b4b;
  font-family: 'Sofia Sans', sans-serif;
  display: flex;
  flex-direction: column;
  padding: 60px;
  text-align: left;
  border-radius: 24px;
  height: 100%;
}

.intro {
  height: auto;
  max-height: 400px;
  color: #acaaaa;
  display: flex;
  align-items: center;
  line-height: 1.5;
  overflow: scroll;
}

.modal-content-default {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-title {
  font-size: 40px;
  color: rgb(37, 37, 37);
  font-weight: 800;
}

.modal-subtitle {
  font-size: 18px;
  color: rgb(151, 151, 151);
}

.modal-content-default p {
  font-size: 14px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.area-name-title {
  font-size: 28px;
  color: rgb(37, 37, 37);
  font-weight: 800;
  padding-bottom: 20px;
}

.subtitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  width: 100%;
  align-content: center;
  }

.area-name-subtitle {
  color: white;
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin: auto;
}

.cur-dropdown {
  bottom: 0px;
  cursor: pointer;
}

.cur-dropdown img{
  height: 18px;
}

.value {
  font-size: 16px;
  color: white;
  font-weight: 800;
}

.show-all-listings {
  width: 90%;
  padding: 10px 0px 10px 0px;
}

.show-all-listings button {
  background: #B19977;
  border: 0;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 15px;
  height: 40px;
  text-align: center;
  width: 100%;
  font-weight: 800;
  border-radius: 20px;
}

.show-all-listings button:hover {
  background: #FFFFFF;
  color: #997C54;
}

.show-all-listings button:focus {
  background: #FFFFFF;
  color: #997C54;
}

.show-listings {
  width: 90%;
  padding: 10px 0px 10px 0px;
}

.show-listings button {
  background: #FFFFFF;
  border: 0;
  color: #B19977;
  cursor: pointer;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 15px;
  height: 40px;
  text-align: center;
  width: 100%;
  font-weight: 800;
  border-radius: 20px;
}

.show-listings button:hover {
  background: #997C54;
  color: #FFFFFF;
}

.show-listings button:focus {
  background: #997C54;
  color: #FFFFFF;
}

.pricesqm-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.mp-dropdown-menu {
  position: absolute;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  right: 0;
  top: 20;
  background-color: white;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 60px;
}

.mp-dropdown-item {
  text-align: right;
  display: inline;
  white-space: nowrap;
}

.mp-dropdown-item:link {
    text-decoration: none;
    color: #323232;
}

.mp-dropdown-item:visited {
  text-decoration: none;
  color: #323232;
}

.mp-dropdown-item:hover {
  color: #B19977;
  font-style: italic;
}


@media (max-width: 768px) {

  .modal-overlay {
    position: fixed;
    top: auto;
    bottom: 0;
    right: 0;
    width: 100%;
    height: auto;
    margin: 0px;
    padding: 0px 0px 40px 0px;
    border-radius: 0px;
    background-color: rgba(30, 37, 44, 0.7);
  }

  .overlay-container {
    height: 100%;
    gap: 10px;
    background-color: transparent;
    border-radius: 0;
  }

  .modal-content-container {
    padding-top: 25px;
  }

  .modal-content-default {
    gap: 10px;
  }

  .modal-content-default p {
    text-align: center;
    font-size: 12px;
  }

  .modal-content {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .area-name-title {
    font-size: 20px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .area-name-subtitle {
    margin: auto;
    align-items: center;
  }

  .show-all-listings {
    padding-top: 0px;
  }

  .show-listings {
    padding-bottom: 0px;
  }

  .subtitle {
    display: flex;
    flex-direction: row;
    font-size: 14px;
  }

  .value {
    margin-bottom: 0px;
    font-size: 14px;
  }

  .pricesqm-wrapper {
    justify-content: center;
  }

  .web-only {
    display: none;
  }

  .mp-dropdown-menu {
    right: 0;
    top: 0;
    bottom: 20;
  }

  .mapboxgl-ctrl-bottom-left {
    display: none;
  }

  .mapboxgl-ctrl-bottom-right {
    display: none;
  }

}
  
</style>
