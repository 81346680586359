<template>
    <div class="photo-tour">
        <div class="photo-tour-wrapper">
            <div class="back" @click="closePhotoTour">
                <button>Back</button>
            </div>
            <div class="image-grid">
                <div v-for="(image, index) in images" :key="index" class="image-item">
                    <img :src="image" :alt="`Image ${index}`" @click.stop="togglePhotoZoom" />
                    <div class="overlay"></div>
                </div>
            </div>
        </div>
    </div>
    <transition name="slide-up">
      <PhotoZoom v-if="showPhotoZoom" @close="togglePhotoZoom" :images="images" />
    </transition>
  </template>  

<script>

import { mapState } from 'vuex';
import PhotoZoom from './PhotoZoom.vue';

export default {

    components: {
        PhotoZoom
    },

    data() {
        return {
            showPhotoZoom: false,
            }
    },

    props: {
        images: Array
    },

    watch: {
        isVisible(newVal) {
            this.$emit('update:isVisible', newVal);
        }
    },

    mounted() {
        document.addEventListener('keydown', this.handleKeydown);
    },

    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeydown);
    },

    computed: {
        ...mapState({
            images: state => state.listingImages,
        }),
    },

    methods: {
        closePhotoTour() {
            this.$emit('close');
        },

        shouldDoubleSpan(index) {
                return (Math.floor(index / 3) + index) % 2 === 1;
        },

        togglePhotoZoom() {            
            this.showPhotoZoom = !this.showPhotoZoom;
        },

        handleKeydown(e) {
            if (e.key === 'Escape') {
                this.closePhotoTour()
            }
        },
    }

}
</script>

<style scoped>

.photo-tour {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 1000;
  transition: transform 0.3s ease;
  gap: 30px;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.photo-tour-wrapper {
    width: 70%;
    display: flex;
    gap: 20px;
    flex-direction: column; 
}

.back {
    padding-top: 20px;
    position: fixed;
    top: 0;
    z-index: 9000;
    background-color: white;
    width: 100%;
    padding-bottom: 20px;
    color: black;
}

.back button {
    border: 0;
    background-color: transparent;
    width: 50px;
    height: 30px;
    cursor: pointer;
    border-radius: 20px;
    color: black;
}

.back button:hover {
    background-color: rgb(241, 241, 241);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.image-item {
  grid-column: span 2;
  position: relative;
  height: 150;
}

.image-item:nth-child(4n - 2), .image-item:nth-child(4n - 1) {
  grid-column: span 1;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
  pointer-events: none;
}

.image-item:hover .overlay {
  background-color: rgba(0, 0, 0, 0.2);
}


</style>