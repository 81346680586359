<template>
  <footer>
    <div class="footer-container">
      <div class="copyright">
        <p>Copyright ©2024 by Estad | All rights reserved</p>
      </div>
      <div class="footer-social">
        <a class="footer-link" href="https://instagram.com/estad.xyz" target="_blank">
          <img src="../assets/instagram.png" alt="Instagram">
        </a>
        <a class="footer-link" href="" target="_blank">
          <img src="../assets/x.png" alt="Twitter">
        </a>
        <a class="footer-link" href="" target="_blank">
          <img src="../assets/discord.png" alt="Discord">
        </a>
      </div>
    </div>
  </footer>
</template>

<style scoped>

footer {
  display: flex;
  justify-content: center;
  height: 60px;
  align-items: center;
  background-color: white;
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
}

.footer-container {
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  
.copyright {
  color: #A0A0A0;
  text-align: left;
  font-size: 16px;
}

.footer-social img {
  height: 30px;
  width: auto;
  margin-left: 15px;
}

@media (max-width: 768px) {

  .footer-container {
    width: 95%;
  }

  .copyright {
    font-size: 12px;
  }

  .footer-social img {
    margin-left: 8px;
    height: 25px;
  }
}

</style>