<template>
    <div class="map-menu">
        <div class="menu-container">
            <div class="menu-group">
                <div class="menu" @click.stop="toggleSearchField" :class="{ 'selected': isSearchFieldOpen }">
                    <img id="menu-search" src="../assets/magnify.svg" />
                    <div class="menu-tooltip">
                        <div class="menu-tooltip-text">Search a place</div>
                    </div>
                    <div class="search-field" v-show="isSearchFieldOpen" ref="searchField" @click.stop>
                        <input type="text" :value="inputValue" @input="filterText = $event.target.value" placeholder="Search a region"/>
                    </div>
                    <div class="menu-dropdown region" v-show="isSearchFieldOpen" >
                        <a 
                            class="menu-dropdown-item" 
                            v-for="(feature, index) in filteredRegions" 
                            :key="feature.properties.name"
                            :class="{
                            'is-highlighted': index === highlightedIndex,
                            'everywhere-style': feature.properties.name === 'Everywhere'
                            }"
                            @mouseenter="highlightedIndex = index"
                            @mouseleave="highlightedIndex = -1"
                            @click.prevent="selectRegion(feature)"
                        >
                            {{ feature.properties.name }}
                        </a>
                    </div>
                </div>
                <div class="menu" @click.prevent="toggleFullScreen" :class="{ 'clicked': isZoom }">
                    <img id="menu-fullscreen" src="../assets/fullscreen.svg" />
                    <div class="menu-tooltip">
                        <div class="menu-tooltip-text">Fullscreen</div>
                    </div>
                </div>
                <div class="menu" @click.prevent="toggleFullScreenLock" :class="{ 'selected': isZoomLocked }">
                    <img id="menu-fullscreenlock" src="../assets/fullscreenlock.svg" />
                    <div class="menu-tooltip">
                        <div class="menu-tooltip-text">Lock fullscreen</div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="menu-group">
                <div class="menu disabled">
                    <img src="../assets/cat_trending2.svg" />
                    <div class="menu-tooltip">
                        <div class="menu-tooltip-text">Trending</div>
                    </div>
                </div>
                <div class="menu disabled">
                    <img src="../assets/cat_upcoming2.svg" />
                    <div class="menu-tooltip">
                        <div class="menu-tooltip-text">Upcoming</div>
                    </div>
                </div>
                <div class="menu disabled">
                    <img src="../assets/cat_undeveloped2.svg" />
                    <div class="menu-tooltip">
                        <div class="menu-tooltip-text">Undeveloped</div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="menu-group">
                <div class="menu" @click.stop="toggleCurrencyDropdown" ref="currencyDropdownButton">
                    {{ selectedCurrency }}
                    <div class="menu-tooltip">
                        <div class="menu-tooltip-text">Change currency</div>
                    </div>
                    <div class="menu-dropdown" v-show="isCurrencyDropdownOpen">
                        <div class="menu-dropdown-item" @click.prevent="selectCurrency('IDR')">IDR</div>
                        <div class="menu-dropdown-item" @click.prevent="selectCurrency('EUR')">EUR</div>
                        <div class="menu-dropdown-item" @click.prevent="selectCurrency('USD')">USD</div>
                        <div class="menu-dropdown-item" @click.prevent="selectCurrency('AUD')">AUD</div>
                        <div class="menu-dropdown-item" @click.prevent="selectCurrency('SGD')">SGD</div>
                        <div class="menu-dropdown-item" @click.prevent="selectCurrency('GBP')">GBP</div>
                        <div class="menu-dropdown-item" @click.prevent="selectCurrency('BTC')">BTC</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile-search" v-show="isSearchFieldOpen">
            <div class="mobile-search-wrapper">
                <div class="mobile-search-field" v-show="isSearchFieldOpen" ref="searchField" @click.stop>
                    <img id="mobile-menu-search" src="../assets/magnify.svg" />
                    <input type="text" :value="inputValue" @input="filterText = $event.target.value" placeholder="Search a region"/>
                </div>
            </div>
            <div class="mobile-menu-dropdown region" v-show="isSearchFieldOpen" >
                <div class="region-wrapper">
                    <a 
                            class="menu-dropdown-item" 
                            v-for="(feature, index) in filteredRegions" 
                            :key="feature.properties.name"
                            :class="{
                            'is-highlighted': index === highlightedIndex,
                            'everywhere-style': feature.properties.name === 'Everywhere'
                            }"
                            @mouseenter="highlightedIndex = index"
                            @mouseleave="highlightedIndex = -1"
                            @click.prevent="selectRegion(feature)"
                        >
                            {{ feature.properties.name }}
                        </a>
                </div>
                <div class="expander"
                    @touchstart="startTouch"
                    @touchmove="moveTouch"
                    >
                    <span class=expander-line></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default{
    name: 'MapMenu',

    data() {
        return {
            isZoomLocked: false,
            isZoom: false,
            selectedCurrency: 'EUR',
            isCurrencyDropdownOpen: false,
            selectedRegion: '',
            isSearchFieldOpen: false,
            filterText: this.selectedRegion || '',
            features: [],
            highlightedIndex: -1,
            touchStartY: 0,
            touchEndY: 0,
        }
    },

    methods: {

        toggleFullScreen() {
            this.isZoom = true;
            this.$store.commit('setMapZoom', true);
        },

        toggleFullScreenLock() {
            this.$store.commit('setMapZoom', this.isZoom);
            this.isZoomLocked = !this.isZoomLocked;
            this.$store.commit('setLockZoom', this.isZoomLocked);
        },

        toggleCurrencyDropdown() {
            this.isCurrencyDropdownOpen = !this.isCurrencyDropdownOpen;
        },

        toggleSearchField() {
            this.isSearchFieldOpen = !this.isSearchFieldOpen;
        },

        handleClickOutside(event) {
            if (this.$refs.currencyDropdownButton && !this.$refs.currencyDropdownButton.contains(event.target)) {
                this.isCurrencyDropdownOpen = false;
            }

            if (this.$refs.searchField && !this.$refs.searchField.contains(event.target)) {
                this.isSearchFieldOpen = false;
            }
        },

        selectCurrency(currency) {
            this.selectedCurrency = currency;
            //this.isCurrencyDropdownOpen = false;
            this.$store.commit('setSelectedCurrency', currency);
        },

        selectRegion(region) {
            console.log('selected dropdown is: '+region)
            if (region.properties.name == 'Everywhere') {
                this.selectedRegion = ''
                this.isZoom = true;
                this.$store.commit('setMapZoom', true);
                this.filterText = '';
            } else {
                this.selectedRegion = region.name;
                this.$store.commit('setSelectedArea', region);
                this.filterText = region.name;
            }
            
            this.highlightedIndex == -1;
            this.isSearchFieldOpen = false;
            this.toggleSearchField();
        },

        clearInput() {
            this.selectedRegion = '';
        },

        handleKeydown(e) {
            const itemsCount = this.filteredRegions.length;

            if (e.key === 'ArrowDown') {
                e.preventDefault();
                if (this.highlightedIndex < itemsCount - 1) {
                this.highlightedIndex++;
                }
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                if (this.highlightedIndex > 0) {
                this.highlightedIndex--;
                }
            } else if (e.key === 'Enter' && this.isSearchFieldOpen) {
                this.selectRegion(this.filteredRegions[this.highlightedIndex]);
                this.isSearchFieldOpen = false;
                this.toggleSearchField();
            } else if (e.key === 'Backspace' && this.isSearchFieldOpen && this.selectedRegion != '') {
                this.clearInput();
                this.filterText = '';
            } else if (e.key === 'Backspace' && this.isSearchFieldOpen && this.filterText === '') {
                this.clearInput();
                this.filterText = '';
            }
        },

        startTouch(event) {
            this.touchStartY = event.touches[0].clientY;
            },

        moveTouch(event) {
            this.touchEndY = event.touches[0].clientY;
        },
    },

    watch: {
        '$store.state.mapZoom': {
            immediate: true,
            handler(newVal, oldVal) {
                if (oldVal !== undefined) {
                this.isZoom = newVal;
                }
            },
        },

        '$store.state.regions': {
            immediate: true,
            handler(newVal, oldVal) {
                console.log('list of regions: '+newVal)
                if (oldVal !== undefined) {
                    const sortedRegions = newVal.sort((a, b) => a.properties.name.localeCompare(b.properties.name));
                    console.log('sortedRegions is: '+sortedRegions)
                    this.features = sortedRegions;
                    console.log('sorted features is: '+this.features)
                }
            },
        },

        filterText(newVal) {
            if (newVal === '') {
                this.selectedRegion = '';
            }
        },
    },

    computed: {
        inputValue() {
            return this.filterText || this.selectedRegion;
        },

        filteredRegions() {            
            let regionoptions;

            if (!this.filterText || this.filterText && this.selectedRegion || this.filterText == '') {
                console.log('option A')
                regionoptions = [...this.features];
            } else {
                console.log('option B')
                regionoptions = this.features.filter(region => 
                region.properties.name.toLowerCase().includes(this.filterText.toLowerCase())
                );
            }

            //const everywhere = { name: 'Everywhere' };
            const everywhere = { properties: { name: 'Everywhere' } };
            regionoptions.unshift(everywhere);

            console.log('regionoptions is: '+regionoptions)

            return regionoptions;
        },
    },

    mounted() {
        this.filterText = this.selectedRegion || '';
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('keydown', this.handleKeydown);
    },

    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
        document.removeEventListener('keydown', this.handleKeydown);
    }
  }
</script>

<style scoped>

.map-menu {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 0px;
    height: 100vh;
    background-color: transparent;
}

.menu-container {
    height: auto;
    width: 50px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 0px 20px 20px 0px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.menu-group {
    display: flex;
    flex-direction: column;
}

.divider {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgb(211, 210, 210);
    margin: 10px;
}

.menu {
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    padding: 15px 0px 15px 0px;
    color: #B19977;
    font-weight: 800;
    position: relative;
}

.menu:hover {
    background-color: #B19977;
    color: #ffffff;
}

.menu.selected {
    background-color: #B19977;
    color: #c19b9b;
}

.menu img {
    height: 24px;
    width: auto;
    transition: filter 0.1s ease;
}

.menu:hover img {
    filter: brightness(0) invert(1);
}

.menu.selected img {
    filter: brightness(0) invert(1);
}

.menu.disabled {
    cursor: default;
    background-color: #dddddd;
}

.menu.disabled img {
    filter: brightness(0) invert(1);
}

.menu-tooltip {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -20%;
    left: 0%;
    z-index: 1;
}

.menu-tooltip .menu-tooltip-text{
    visibility: hidden;
    position: absolute;
    left: 120%;
    color: #6b6b6b;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px 10px 10px 0px;
    font-size: 12px;
    white-space: nowrap;
}

.menu-tooltip:hover .menu-tooltip-text {
    visibility: visible;
    width: auto;
}

.menu-dropdown {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    left: 120%;
    top: 0%;
    display: flex;
    flex-direction: column;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 20px;
    width: 80px;
}

.menu-dropdown.region {
    top: 145%;
    width: 360px;
    max-height: 300px;
    overflow: scroll;
}

.menu-dropdown-item {
    width: 100%;
    color: #B19977;
    text-decoration: none;
    padding: 5px 0px 5px 0px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.menu-dropdown-item.everywhere-style {
    font-weight: bold;
    border-bottom: 1px solid rgb(220, 218, 218);
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.menu-dropdown-item:hover {
    background-color: #B19977;
    color: #ffffff;
}

.menu-dropdown-item.is-highlighted {
    color: #ffffff;
    background-color: #B19977;
}

.search-field {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
    left: 120%;
    top: 0%;
    display: flex;
    height: 60px;
    width: 300px;
    border-radius: 20px;
    padding: 5px 30px 5px 30px;
}

.search-field input {
    border: 0;
    background-color: transparent;
    font-size: 16px;
    color: rgb(114, 114, 114);
    font-family: 'Sofia Sans', sans-serif;
}

.search-field input:focus {
  outline: none;
}

.mobile-search {
    display: none;
}

.expander {
    display: none;
}

@media (max-width: 768px) {

    .map-menu {
        left: auto;
        top: 60px;
        flex-direction: row;
        width: 100vw;
        height: 0px;
        position: fixed;
        justify-content: space-between;
    }

    .menu-container {
        height: 35px;
        width: 100vw;
        border-radius: 0px 0px 0px 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
    }

    .menu-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .menu {
        flex-direction: column;
        position: auto;
        padding: 10px;
    }

    .menu img {
        height: 15px;
        width: auto;
        transition: filter 0.1s ease;
    }

    .divider {
        flex-direction: row;
        border-right: 1px solid rgb(211, 210, 210);
    }

    .menu-tooltip {
        display: none;
    }

    .search-field {
        display: none;
    }

    .menu-dropdown {
        display: none;
    }

    .mobile-search {
        position: fixed;
        left: 0;
        width: 100%;
        background-color: #ffffff;
        height: 35px;
        display: block;
    }

    .mobile-search-wrapper {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    .mobile-search-field {
        left: 100%;
        height: 100%;
        width: 100vw;
        border-radius: 0px;
        padding: 0px 5px 0px 5px;
        z-index: 1000;
        background-color: rgba(255, 255, 255);
        display: flex;
        align-items: center;
        gap: 10px;
    }

    #mobile-menu-search {
        height: 15px;
        width: auto;
    }

    .mobile-search-field input {
        width: 100%;
        border: 0;
    }

    .mobile-search-field input:focus {
        outline: none;
    }

    .mobile-menu-dropdown.region {
        width: 100%;
        background-color: rgba(255, 255, 255);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .region-wrapper {
        width: 80%;
        max-height: 150px;
        overflow: scroll;
    }

    .menu-dropdown-item {
        justify-content: flex-start;
    }

    .expander {
        height: 20px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
    }

    .expander-line {
        height: 3px;
        width: 80px;
        background-color: #d8d8d8;
        border-radius: 5px;
        display: flex;
        position: fixed;
    }

}


</style>