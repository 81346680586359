<template>
  <div class="search-heading-container">
    <div class="search-heading" v-show="showFullSize">
      <a class="return-link" href="/">Return to map</a>
      <div class="area-name">{{ regionName }}</div>
      <div class="search-count">{{ totalProperties }} {{ ownershipType }} properties found</div>
    </div>
    <div class="search-heading-shrunk" v-show="showSmallSize">
      <a class="return-link" href="/">Return to map</a>
      <div class="area-name-shrunk">
        {{ regionName }}
        <span class="search-count-shrunk">({{ totalProperties }} {{ ownershipType }} properties found)</span>
      </div>
    </div>
    <div class="search-heading-mobile">
      <div class="area-name">{{ regionName }}</div>
      <div class="search-count">{{ totalProperties }} {{ ownershipType }} properties found</div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';

export default {

  data() {
    return {
      defaultRegionName: 'Bali Real Estates',
      showFullSize: true,
      showSmallSize: false
    };
  },

  computed: {
    ...mapState({
      totalProperties: state => state.results.totalProperties,
      ownershipType: state => state.filters.type
    }),

    regionName() {
      return this.$store.state.results.regionName || this.defaultRegionName;
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll() {
      if (window.scrollY > 100) {
        this.showFullSize = false;
        this.showSmallSize = true;
      } else {
        this.showFullSize = true;
        this.showSmallSize = false;
      }
    },
  },
};

</script>

<style scoped>

.search-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  z-index: 1;
  position: sticky;
  position: -webkit-sticky;
  top: 60px;
  margin: auto;
  width: 100%;
  background-color: white;
}

.search-heading {
  text-align: center;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.search-heading-mobile {
  display: none;
}

.search-heading-shrunk {
  text-align: center;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.return-link {
  font-family: 'Sofia Sans', sans-serif;
  font-size: 12px;
}

.return-link:link {
  text-decoration: none;
  color: #3A6083;
}

.return-link:visited {
  text-decoration: none;
  color: #3A6083;
}

.return-link:active {
  text-decoration: none;
  color: #3A6083;
}

.return-link:hover {
  color: #0b2844;
}

.area-name {
  color: #323232;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 40px;
}

.area-name-shrunk {
  color: #323232;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 24px;
}

.search-count {
  color: #B19977;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 16px;
}

.search-count-shrunk {
  color: #B19977;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 18px;
}

@media (max-width: 768px) {

  .search-heading-shrunk {
    display: none
  }

  .search-heading-mobile {
    text-align: center;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  .search-heading-container {
    width: 100%;
    top: 60px;
    background-color: white;
  }

  .search-heading {
    display: none;
  }

  .area-name {
    font-size: 20px;
  }

}

</style>
