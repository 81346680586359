import { createStore } from 'vuex';

export default createStore({
  state: {
    results: {
      totalProperties: 0,
      regionName: '',
    },
    filters: {
        type: '',
        minPrice: '',
        maxPrice: '',
        region: '',
        currency: '',
        buildingSize: '',
        landSize: '',
        bedroom: '',
        bathroom: '',
        sort: '',
      },
    isLoading: false,
    selectedListing: {},
    selectedCurrency: 'EUR',
    listingImages: {},
    cloudinary: {
      cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
    },
    redirectUrl: '',
    selectedArea: {},
    mapZoom: true,
    lockZoom: false,
    regions: {}
  },

  getters: {
    cloudinaryConfig: (state) => state.cloudinary
  },

  mutations: {
    searchResult(state, { name, value } ) {
      state.results = { ...state.results, [name]: value };
    },

    setFilter(state, { filterName, value }) {
      state.filters = { ...state.filters, [filterName]: value };
    },

    setLoading(state, isLoading) {
        state.isLoading = isLoading;
      },

    setSelectedListing(state, listing) {
      state.selectedListing = listing;
    },

    setSelectedCurrency(state, currency) {
      state.selectedCurrency = currency;
    },

    setListingImages(state, listingImages) {
      state.listingImages = listingImages;
    },

    remove_filter(state, key) {
      if (key in state.filters) {
        state.filters[key] = ''; 
      }
    },

    setRedirectUrl(state, redirectUrl) {
      state.redirectUrl = redirectUrl;
    },

    setSelectedArea(state, area) {
      state.selectedArea = area;
    },

    setMapZoom(state, value) {
      state.mapZoom = value;
    },

    setLockZoom(state, value) {
      state.lockZoom = value;
    },

    storeRegions(state, value) {
      state.regions = value;
    },

  },

  actions: {
    saveSearchResultInfo({ commit }, { name, value }) {
      commit('searchResult', { name, value });
    },

    applyFilter({ commit }, { filterName, value }) {
        commit('setFilter', { filterName, value });
    },

    fetchData({ commit }) {
        commit('setLoading', true);
        commit('setLoading', false);
      },
    
    storeListingData({ commit }, listing) {
      commit('setSelectedListing', listing);
    },

    storeSelectedCurrency({ commit }, currency) {
      commit('setSelectedCurrency', currency);
    },

    storeListingImages({ commit }, listingImages) {
      commit('setListingImages', listingImages);
    },

    fetchListingById({ commit }, listingId) {
      const baseUrl = 'https://us-central1-realsqm-8ca89.cloudfunctions.net/api/villas';
      fetch(`${baseUrl}?limit=1&listingId=${listingId}`)
        .then(response => {
          if (!response.ok) {
            commit('setLoading', true);
            throw new Error('Failed to fetch the listing');
          }
          return response.json();
        })
        .then(data => {
          const listing = (data.villas);

          if (listing && listing.length > 0) {
              commit('setSelectedListing', listing[0]);
            }
          })
          
        .catch(error => {
          console.error('Error fetching listing:', error);
        });
    },

    fetchListings({ commit, state }) {
      const params = new URLSearchParams(state.filters).toString();
      const url = `https://us-central1-realsqm-8ca89.cloudfunctions.net/api/villas?${params}`;
    
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch listings');
          }
          return response.json();
        })
        .then(data => {
          commit('searchResult', { name: 'totalProperties', value: data.total });
        })
        .catch(error => {
          console.error('Error fetching listings:', error);
        });
    },

    removeFilterTag({ commit }, key) {
      commit('remove_filter', key);
    },

    saveRedirectUrl({ commit }, redirectUrl) {
      commit('setRedirectUrl', redirectUrl);
    },

    fetchGeojson() {
      const url = `https://us-central1-realsqm-8ca89.cloudfunctions.net/api/geojson`;
    
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch geojson');
          }
          return response.json();
        })
        .catch(error => {
          console.error('Error fetching listings:', error);
        });
    },
  }
});
