<template>
  <MapboxContainer v-if="!isMobile" />
  <MapStory v-if="!isMobile" />
  <MapMenu v-if="!isMobile" />
  <HomePageMobile v-if="isMobile" />
</template>

<script>
import MapboxContainer from '@/components/MapboxContainer.vue';
import MapStory from '@/components/MapStory.vue';
import MapMenu from '@/components/MapMenu.vue';
import HomePageMobile from '@/components/HomePageMobile.vue';

export default {
  name: 'HomePage',
  components: {
    MapboxContainer,
    MapStory,
    MapMenu,
    HomePageMobile
  },
  data() {
    return {
      isMobile: false
    };
  },
  created() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  }
};
</script>

<style>
/* Add any global styles if needed */
</style>
