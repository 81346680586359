<template>
    <SiteHeader />
    <router-view/>
    <SiteFooter />
</template>

<script>
import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  name: 'App',
  components: {
    SiteHeader,
    SiteFooter
  }
};
</script>

<style>
.dropdown-filter {
  background-color: #ffffff;
  border: 1px solid #A0A0A0;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 10px 0 10px;
  flex-direction: row;
  gap: 6px;
  font-size: inherit;
  position: relative;
  color: #3A6083;
  border-radius: 15px;
}

.dropdown-filter img {
  height: 50%;
  width: auto;
}

.dropdown-filter:hover {
  background-color: #F3F3F3;
}

.dropdown-filter::after {
  background-color: #F3F3F3;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 50px;
  left: 0;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.dropdown-item {
  text-align: left;
  display: inline;
  white-space: nowrap;
}

.dropdown-item:link {
  text-decoration: none;
  color: #323232;
}

.dropdown-item:visited {
  text-decoration: none;
  color: #323232;
}

.dropdown-item:hover {
  color: #B19977;
  font-style: italic;
}

@media (max-width: 768px) {

  .dropdown-filter span {
    text-overflow: ellipsis;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
