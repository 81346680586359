<template>
  <div class="filter-sidebar" v-show="isVisible">
    <div class="sidebar-container">
        <div class="fs-filter-tags-container">
          <div class="fs-filter-tags">
            <div class="fs-tags">
              <span v-for="(value, key) in cleanFilters" :key="key" class="fs-tag" @click="removeFilter(key)">
                <span class="fs-tag-text">
                  {{ formatKey(key) }} {{ formatValue(value, key) }}
                </span>
                <img src="../assets/remove_tag.svg"/>
              </span>
            </div>
          </div>
      </div>
    <div class="filter-option">
        <span class="filter-title">Region</span>
        <div class="filter-container">
          <div class="filter-dropdown-toggle" ref="regionDropdownButton" @click.prevent="toggleRegionDropdown">
            <input type="text" :value="inputValue" @input="filterText = $event.target.value" placeholder="Search a region" class="search-region" />
            <div @click.stop="clearInput" class="clear-input" v-if="filterText != '' && filterText != 'Everywhere'">
              <img src="../assets/remove_tag.svg"/>
            </div>
            <div class="filter-dropdown-list" v-show="isRegionDropdownOpen" >
              <a 
                class="filter-dropdown-item"
                v-for="(region, index) in filteredRegions"
                :key="region.name"
                :class="{
                  'is-highlighted': index === highlightedIndex,
                  'everywhere-style': region.name === 'Everywhere'
                }"
                @mouseenter="highlightedIndex = index"
                @mouseleave="highlightedIndex = -1"
                @click.prevent="selectRegion(region.name)"
              >
                {{ region.name }}
              </a>
            </div>
          </div>
        </div>
    </div>
    <div class="filter-option">
        <span class="filter-title">Type of Contract</span>
        <div class="select-all-contracts">
            <input id="select-all-checkbox" type="checkbox" v-model="selectAllContracts" />
            <div>Show all contracts</div>
          </div>
        <div class="filter-container">
          <div class="ownership" :class="{ 'disabled': selectAllContracts }">
            <button :class="{'button-clicked': leaseholdClicked}" @click.prevent="toggleOwnership('leasehold')" :disabled="selectAllContracts">Leasehold</button>
            <button :class="{'button-clicked': freeholdClicked}" @click.prevent="toggleOwnership('freehold')" :disabled="selectAllContracts">Freehold</button>
          </div>
        </div>
    </div>
    <div class="filter-option">
        <span class="filter-title">Price Range</span>
        <div class="filter-container">
          <div class="budget">
            <input type="text" placeholder="Minimum Price" v-model="formattedMinPrice" @input="enterMinPrice">
            <span class="currency">{{ currency }}</span>
          </div>
          <div class="budget">
            <input type="text" placeholder="Maximum Price" v-model="formattedMaxPrice" @input="enterMaxPrice">
            <span class="currency">{{ currency }}</span>
          </div>
        </div>
    </div>
    <div class="filter-option">
        <span class="filter-title">Building Size</span>
        <div class="filter-container">
          <button class="size" :class="{'button-clicked': buildingSizeAnyClicked}" @click="toggleBuildingSize('Any')">Any</button>
          <button class="size" :class="{'button-clicked': buildingSize1Clicked}" @click="toggleBuildingSize('1')">0-300 sqm</button>
          <button class="size" :class="{'button-clicked': buildingSize2Clicked}" @click="toggleBuildingSize('2')">300+ sqm</button>
        </div>
    </div>
    <div class="filter-option">
        <span class="filter-title">Land Size</span>
        <div class="filter-container">
          <button class="size" :class="{'button-clicked': landSizeAnyClicked}" @click="toggleLandSize('Any')">Any</button>
          <button class="size" :class="{'button-clicked': landSize1Clicked}" @click="toggleLandSize('1')">0-300 sqm</button>
          <button class="size" :class="{'button-clicked': landSize2Clicked}" @click="toggleLandSize('2')">300+ sqm</button>
        </div>
    </div>
    <div class="filter-option">
      <span class="filter-title">Rooms</span>
      <div class="filter-container">
        <div class="rooms">Bedrooms
          <div class="filter-dropdown-toggle" ref="bedroomDropdownButton" @click.prevent="toggleBedroomDropdown">
            <span>{{ formatValue(selectedBedroom, 'bedroom') }}</span>
            <div class="filter-dropdown-list" v-show="isBedroomDropdownOpen" >
              <a class="filter-dropdown-item" @click="selectBedroom('Any')">Any</a>
              <a class="filter-dropdown-item" @click="selectBedroom('1')">0-3</a>
              <a class="filter-dropdown-item" @click="selectBedroom('2')">3+</a>
            </div>
          </div>
        </div>
        <div class="rooms">Bathrooms
          <div class="filter-dropdown-toggle" ref="bathroomDropdownButton" @click.prevent="toggleBathroomDropdown">
            <span>{{ formatValue(selectedBathroom, 'bathroom') }}</span>
            <div class="filter-dropdown-list" v-show="isBathroomDropdownOpen" >
              <a class="filter-dropdown-item" @click="selectBathroom('Any')">Any</a>
              <a class="filter-dropdown-item" @click="selectBathroom('1')">0-3</a>
              <a class="filter-dropdown-item" @click="selectBathroom('2')">3+</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="cta">
      <div class="filter-container">
        <button class="reset" @click.prevent="resetFilters">Reset All</button>
        <button class="apply" @click.prevent="applyFilters">Show {{ totalResult }} properties</button>
      </div> 
    </div>
  </div>
  </template>

<script>

import { mapState } from 'vuex';
import axios from 'axios';

export default {

  data() {
    return {
      leaseholdClicked: false,
      freeholdClicked: false,
      buildingSizeAnyClicked: true,
      buildingSize1Clicked: true,
      buildingSize2Clicked: true,
      landSizeAnyClicked: true,
      landSize1Clicked: true,
      landSize2Clicked: true,
      ownershipType: '',
      landSize: '',
      buildingSize: '',
      defaultCurrency: 'EUR',
      minPrice: '',
      maxPrice: '',
      formattedMinPrice: '',
      formattedMaxPrice: '',
      regions: [],
      isRegionDropdownOpen: false,
      selectedRegion: '',
      filterText: this.selectedRegion || 'Everywhere',
      highlightedIndex: -1,
      isBedroomDropdownOpen: false,
      isBathroomDropdownOpen: false,
      selectedBedroom: 'Any',
      selectedBathroom: 'Any',
      totalResult: 0,
      debounceTimeout: null,
      selectAllContracts: false
    };
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    ...mapState({
      type: state => state.filters.type,
      filters: state => state.filters,
    }),

    currency() {
      return this.$store.state.filters.currency || this.defaultCurrency;
    },

    cleanFilters() {

        // Get raw filters from the store
        const filters = this.filters;
        const nonEmptyFilters = {};

        // Only include certain filters
        for (const key in filters) {
        if (key !== 'currency' && key !== 'sort' && filters[key] !== '' && filters[key] !== 'Any') {
          nonEmptyFilters[key] = filters[key];
        }}
        
        return nonEmptyFilters;
      },

    filteredRegions() {
      let regionoptions;

      if (!this.filterText || this.filterText && this.selectedRegion || this.filterText == 'Everywhere') {
        regionoptions = [...this.regions];
      } else {
        regionoptions = this.regions.filter(region => 
          region.name.toLowerCase().includes(this.filterText.toLowerCase())
        );
      }

      const everywhere = { name: 'Everywhere' };
      regionoptions.unshift(everywhere);

      return regionoptions;
    },

    inputValue() {
      /*if (!this.filterText) {
        return 'Everywhere'
      }

      else {
        return this.filterText || this.selectedRegion;
      }*/

      return this.filterText || this.selectedRegion;
    }

  },

  methods: {
    closeSidebar() {
      this.$emit('update:isVisible', false);
    },

    toggleOwnership(type) {
      this.leaseholdClicked = false;
      this.freeholdClicked = false;

      if (type === 'leasehold') {
        this.leaseholdClicked = true;
        this.ownershipType = 'leasehold';
        this.$store.dispatch('applyFilter', { filterName: 'type', value: this.ownershipType });
      } else if (type === 'freehold') {
        this.freeholdClicked = true;
        this.ownershipType = 'freehold';
        this.$store.dispatch('applyFilter', { filterName: 'type', value: this.ownershipType });
      }

      this.$store.dispatch('fetchListings', {});
    },

    toggleBuildingSize(sizeType) {

      // Reset all size buttons state
      this.buildingSizeAnyClicked = false;
      this.buildingSize1Clicked = false;
      this.buildingSize2Clicked = false;

      // Set the clicked state for the selected size button
      if (sizeType === 'Any') {
        this.buildingSizeAnyClicked = true;
        this.buildingSize = '';
      } else if (sizeType === '1') {
        this.buildingSize1Clicked = true;
        this.buildingSize = 1;
      } else if (sizeType === '2') {
        this.buildingSize2Clicked = true;
        this.buildingSize = 2;
      }

      this.$store.dispatch('applyFilter', { filterName: 'buildingSize', value: this.buildingSize });
      this.$store.dispatch('fetchListings', {});
    },

    toggleLandSize(landSizeType) {

      // Reset all size button states
      this.landSizeAnyClicked = false;
      this.landSize1Clicked = false;
      this.landSize2Clicked = false;

      // Set the clicked state for the selected size button
      if (landSizeType === 'Any') {
        this.landSizeAnyClicked = true;
        this.landSize = '';
      } else if (landSizeType === '1') {
        this.landSize1Clicked = true;
        this.landSize = 1;
      } else if (landSizeType === '2') {
        this.landSize2Clicked = true;
        this.landSize = 2;
      }

      this.$store.dispatch('applyFilter', { filterName: 'landSize', value: this.landSize });
      this.$store.dispatch('fetchListings', {});
    },

    toggleRegionDropdown() {
      this.isRegionDropdownOpen = !this.isRegionDropdownOpen;
    },

    toggleBedroomDropdown() {
      this.isBedroomDropdownOpen = !this.isBedroomDropdownOpen;
    },

    toggleBathroomDropdown() {
      this.isBathroomDropdownOpen = !this.isBathroomDropdownOpen;
    },

    enterMinPrice() {
      if (this.formattedMinPrice === '') {
        this.minPrice = '';
      } else {
        const numericValue = this.formattedMinPrice.replace(/\D/g, '');
        this.minPrice = parseInt(numericValue, 10) || '';
      }

      // Only format if minPrice is not an empty string
      this.formattedMinPrice = this.minPrice === '' ? '' : this.formatValue(this.minPrice);
      this.$store.dispatch('applyFilter', { filterName: 'minPrice', value: this.minPrice });

      clearTimeout(this.debounceTimeout);
      
      this.debounceTimeout = setTimeout(() => {
        this.$store.dispatch('fetchListings', {});
      }, 1000);
    },

    enterMaxPrice() {
      if (this.formattedMaxPrice === '') {
        this.maxPrice = '';
      } else {
        const numericValue = this.formattedMaxPrice.replace(/\D/g, '');
        this.maxPrice = parseInt(numericValue, 10) || '';
      }

      // Only format if maxPrice is not an empty string
      this.formattedMaxPrice = this.maxPrice === '' ? '' : this.formatValue(this.maxPrice);
      this.$store.dispatch('applyFilter', { filterName: 'maxPrice', value: this.maxPrice });

      this.debounceTimeout = setTimeout(() => {
        this.$store.dispatch('fetchListings', {});
      }, 1000);
    },

    applyFilters() {
      // Start with a copy of the current route's query, then modify or delete parameters as needed
      let query = { ...this.$route.query };

      // Update query parameters based on current values, or delete them if they are empty
      query.type = this.ownershipType !== '' ? this.ownershipType : undefined;
      query.minPrice = this.minPrice !== '' ? this.minPrice : undefined;
      query.maxPrice = this.maxPrice !== '' ? this.maxPrice : undefined;
      query.region = this.selectedRegion !== '' ? this.selectedRegion : undefined;
      query.buildingSize = this.buildingSize !== '' ? this.buildingSize : undefined;
      query.landSize = this.landSize !== '' ? this.landSize : undefined;
      query.bedroom = this.selectedBedroom !== 'Any' ? this.selectedBedroom : undefined;
      query.bathroom = this.selectedBathroom !== 'Any' ? this.selectedBathroom : undefined;

      // Clean up the query object by removing properties that are undefined
      query = Object.entries(query).reduce((acc, [key, value]) => {
        if (value !== undefined) acc[key] = value;
        return acc;
      }, {});

      // Use the cleaned-up query object in the router push
      this.$router.push({ 
        path: '/search',
        query 
      });

      this.$store.dispatch('fetchData');
      this.closeSidebar();
    },

    resetFilters() {
      this.leaseholdClicked = true;
      this.freeholdClicked = false;
      this.ownershipType = 'leasehold';
      this.minPrice = '';
      this.maxPrice = '';
      this.formattedMinPrice = '';
      this.formattedMaxPrice = '';
      this.selectedRegion = '';
      this.buildingSize = '';
      this.landSize = '';
      this.buildingSizeAnyClicked = true;
      this.buildingSize1Clicked = false;
      this.buildingSize2Clicked = false;
      this.landSizeAnyClicked = true;
      this.landSize1Clicked = false;
      this.landSize2Clicked = false;
      this.removeFilter('minPrice');
      this.removeFilter('maxPrice');
      this.removeFilter('region');
      this.removeFilter('buildingSize');
      this.removeFilter('landSize');
      this.removeFilter('bedroom');
      this.removeFilter('bathroom');
      this.filterText = 'Everywhere';
      this.selectedBedroom = 'Any';
      this.selectedBathroom = 'Any';
      this.$store.dispatch('fetchListings', {});
    },

    getRegions() {
      axios.get(`https://us-central1-realsqm-8ca89.cloudfunctions.net/api/regions`)
        .then(response => {

          const regions = response.data.regions;
      
          const filteredRegions = regions.filter(region => region.name !== 'all_bali');

          const sortedRegions = filteredRegions.sort((a, b) => a.name.localeCompare(b.name));

          this.regions = sortedRegions;
        })
        .catch(error => {
          console.error('Error fetching regions:', error);
        });
    },

    selectRegion(region) {
      if (region == 'Everywhere') {
        this.selectedRegion = ''
      } else this.selectedRegion = region;
    
      this.$store.dispatch('applyFilter', { filterName: 'region', value: this.selectedRegion });
      this.filterText = region;
      this.highlightedIndex == -1;
      this.isRegionDropdownOpen = false;
      this.toggleRegionDropdown();
      this.$store.dispatch('fetchListings', {});
    },

    selectBedroom(bedroom) {
      this.selectedBedroom = bedroom;
      
      if (this.selectedBedroom == 'Any') {
        this.$store.dispatch('applyFilter', { filterName: 'bedroom', value: '' });
      } else this.$store.dispatch('applyFilter', { filterName: 'bedroom', value: this.selectedBedroom });
    
      this.isBedroomDropdownOpen = false;
      this.toggleBedroomDropdown();
      this.$store.dispatch('fetchListings', {});
    },

    selectBathroom(bathroom) {
      this.selectedBathroom = bathroom;

      if (this.selectedBathroom == 'Any') {
        this.$store.dispatch('applyFilter', { filterName: 'bathroom', value: '' });
      } else this.$store.dispatch('applyFilter', { filterName: 'bathroom', value: this.selectedBathroom });
    
      this.isBathroomDropdownOpen = false;
      this.toggleBathroomDropdown();
      this.$store.dispatch('fetchListings', {});
    },

    handleClickOutside(event) {
      if (!this.$refs.regionDropdownButton.contains(event.target)) {
        this.isRegionDropdownOpen = false;
      }

      if (!this.$refs.regionDropdownButton.contains(event.target) && this.selectedRegion == '') {
        this.filterText = 'Everywhere';
      }

      if (!this.$refs.bedroomDropdownButton.contains(event.target)) {
        this.isBedroomDropdownOpen = false;
      }

      if (!this.$refs.bathroomDropdownButton.contains(event.target)) {
        this.isBathroomDropdownOpen = false;
      }
    },

    formatKey(key) {

      // Format key from filters store
      if (key === 'minPrice') {
        return 'Min: ';
      } else if (key === 'maxPrice') {
        return 'Max: ';
      } else if (key === 'region') {
        return '';
      } else if (key === 'buildingSize') {
        return 'Building: ';
      } else if (key === 'landSize') {
        return 'Land: ';
      } else if (key === 'bedroom') {
        return 'Bedroom: ';
      } else if (key === 'bathroom') {
        return 'Bathroom: ';
      } else if (key === 'type') {
        return '';
      } else {
        return key;
      }
    },

    removeFilter(key) {
      this.$store.dispatch('applyFilter', { filterName: key, value: '' });

      if (key == 'minPrice') {
        this.minPrice = '';
        this.formattedMinPrice = '';
      }

      if (key == 'maxPrice') {
        this.maxPrice = '';
        this.formattedMaxPrice = '';
      }

      if (key == 'region') {
        this.selectedRegion = '';
        this.filterText = '';
      }

      if (key == 'buildingSize') {
        this.buildingSize = '';
        this.buildingSizeAnyClicked = true;
        this.buildingSize1Clicked = false;
        this.buildingSize2Clicked = false;
      }

      if (key == 'landSize') {
        this.landSize = '';
        this.landSizeAnyClicked = true;
        this.landSize1Clicked = false;
        this.landSize2Clicked = false;
      }

      if (key == 'bedroom') {
        this.selectedBedroom = 'Any';
      }

      if (key == 'bathroom') {
        this.selectedBathroom = 'Any';
      }

      if (key == 'type') {
        this.leaseholdClicked = false;
        this.freeholdClicked = false;
        this.ownershipType = '';
      }

      this.$store.dispatch('fetchListings', {});
    },

    handleKeydown(e) {
      const itemsCount = this.filteredRegions.length;

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (this.highlightedIndex < itemsCount - 1) {
          this.highlightedIndex++;
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (this.highlightedIndex > 0) {
          this.highlightedIndex--;
        }
      } else if (e.key === 'Enter' && this.isRegionDropdownOpen) {
        this.selectRegion(this.filteredRegions[this.highlightedIndex].name);
        this.toggleRegionDropdown();
      } else if (e.key === 'Enter' && !this.isRegionDropdownOpen) {
        this.applyFilters();
      } else if (e.key === 'Backspace' && this.isRegionDropdownOpen && this.selectedRegion != '') {
        this.clearInput();
        this.filterText = '';
      } else if (e.key === 'Backspace' && this.isRegionDropdownOpen && this.filterText === 'Everywhere') {
        this.clearInput();
        this.filterText = '';
      }
    },

    clearInput() {
      //this.filterText = 'Everywhere';
      this.selectedRegion = '';
      this.removeFilter('region');
      this.$store.dispatch('fetchListings', {});
    },

    formatValue(value, key) {
      if (key === 'minPrice' || key === 'maxPrice' || key === null) {
        if (value === '' || value === undefined) {
          return '';
        }

        const number = parseFloat(value);
        if (isNaN(number)) {
          return value;
        }

        return number.toLocaleString();
      }

      else if (key === 'landSize' || key === 'buildingSize') {
        if (value === 1) {
          return '0-300 sqm'
        } else if (value === 2) {
          return '300+ sqm'
        }
      }

      else if (key === 'bedroom' || key === 'bathroom') {
        if (value === '1') {
          return '0-3'
        } else if (value === '2') {
          return '3+'
        } else if (value === 'Any') {
          return 'Any'
        }
      }

      else if (key === 'type') {
        if (value === 'leasehold') {
          return 'Lease Hold'
        } else if (value === 'freehold') {
          return 'Free Hold'
        } else return ''
      }

      else return value
    }

  },

  mounted() {
    this.getRegions()
    this.filterText = this.selectedRegion || 'Everywhere';
    document.addEventListener('click', this.handleClickOutside);
    document.addEventListener('keydown', this.handleKeydown);
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleKeydown);
  },

  watch: {
    '$route.query': {
      immediate: true,
      handler(query) {
        if (query.type === 'freehold') {
          this.ownershipType = 'freehold';
          this.freeholdClicked = true;
        } else if (query.type === 'leasehold') {
          this.ownershipType = 'leasehold';
          this.leaseholdClicked = true;
        } else if (!query.type || query.type === '' || query.type != 'leasehold' || query.type != 'freehold') {
          this.ownershipType = '';
          this.leaseholdClicked = false;
          this.selectAllContracts = true;
        }
 
        this.minPrice = query.minPrice;
        this.maxPrice = query.maxPrice;

        if (query.region && query.region != '') {
          this.filterText = query.region;
          this.selectedRegion = query.region;
        } else if (!query.region || query.region == '') {
          this.filterText = 'Everywhere';
          this.selectedRegion = '';
        }

        if (query.bedroom) {
          this.selectedBedroom = query.bedroom;
        } else if (!query.bedroom || query.bedroom == '') {
          this.selectedBedroom = 'Any';
          this.selectBedroom('Any');
        }

        if (query.bathroom) {
          this.selectedBathroom = query.bathroom;
        } else if (!query.bathroom || query.bathroom == '') {
          this.selectedBathroom = 'Any';
          this.selectBathroom('Any');
        }

        if (query.minPrice) {
          this.formattedMinPrice = this.formatValue(query.minPrice);
        } else this.formattedMinPrice = ''

        if (query.maxPrice) {
          this.formattedMaxPrice = this.formatValue(query.maxPrice);
        } else this.formattedMaxPrice = ''

        if (!query.buildingSize || query.buildingSize == '') {
          this.buildingSizeAnyClicked = true
          this.buildingSize1Clicked = false
          this.buildingSize2Clicked = false
          this.buildingSize = ''
        } else if (query.buildingSize == 1) {
          this.buildingSizeAnyClicked = false
          this.buildingSize1Clicked = true
          this.buildingSize2Clicked = false
          this.buildingSize = 1
        } else if (query.buildingSize == 2) {
          this.buildingSizeAnyClicked = false
          this.buildingSize1Clicked = false
          this.buildingSize2Clicked = true
          this.buildingSize = 2
        } 

        if (!query.landSize || query.landSize == '') {
          this.landSizeAnyClicked = true
          this.landSize1Clicked = false
          this.landSize2Clicked = false
          this.landSize = ''
        } else if (query.landSize == 1) {
          this.landSizeAnyClicked = false
          this.landSize1Clicked = true
          this.landSize2Clicked = false
          this.landSize = 1
        } else if (query.landSize == 2) {
          this.landSizeAnyClicked = false
          this.landSize1Clicked = false
          this.landSize2Clicked = true
          this.landSize = 2
        }
      }
    },

    '$store.state.filters.region': {
      immediate: true,
      handler(newVal) {
        this.selectedRegion = newVal;
      }
    },

    filterText(newVal) {
      if (newVal === '') {
        this.selectedRegion = '';
      }
    },

    minPrice(newValue) {
      this.formattedMinPrice = this.formatValue(newValue);
    },

    maxPrice(newValue) {
      this.formattedMaxPrice = this.formatValue(newValue);
    },

    '$store.state.results.totalProperties': {
      immediate: true,
      handler(newVal) {
        this.totalResult = newVal;
      }
    },

    selectAllContracts(newVal) {
        if (newVal === true) {
            this.leaseholdClicked = false;
            this.freeholdClicked = false;
            this.ownershipType = '';
        } else {
          this.leaseholdClicked = true;
          this.freeholdClicked = false;
          this.ownershipType = 'leasehold';
        }

        this.$store.dispatch('applyFilter', { filterName: 'type', value: this.ownershipType });
        this.$store.dispatch('fetchListings', {});
    }
  }
}
</script>

<style scoped>

.filter-sidebar {
  font-family: 'Sofia Sans', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  width: 500px;
  background-color: white;
  z-index: 4;
  transition: transform 0.3s ease;
  max-height: 100vh;
  overflow: scroll;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 50px;
  height: 100vh;
}

.close-toggle {
  background-color: #ffffff;
  border: 1px solid #A0A0A0;
  height: 40px;
  width: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Sofia Sans', sans-serif;
  padding: 0 20px 0 20px;
  justify-content: space-between;
  border-radius: 20px;
}

.close-toggle:hover {
  background-color: #F3F3F3;
}

.close-toggle img {
  height: 20px;
  width: auto;
  padding-right: 6px;
}

.filter-option {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
  font-size: 16px;
  border-top: 1px solid #e8e4e4;
  padding-top: 15px;
}

.filter-title {
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
}

.filter-container {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-family: 'Sofia Sans', sans-serif;
}

.filter-container .button-clicked {
  background-color: #3A6083;
  color: #FFFFFF;
  border: 0;
}

.filter-container .button-clicked:hover {
  background-color: #264A6B;
  color: #FFFFFF;
  border: 0;
}

.filter-container button {
  background-color: transparent;
  height: 40px;
  cursor: pointer;
  color: #323232;
  flex-grow: 1;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 14px;
  border: 1px solid #A0A0A0;
  width: 100%;
  border-radius: 15px;
}

.filter-container button:hover {
  background-color: #F5F5F5;
}

.ownership {
  display: flex;
  flex-direction: row;
  border: 1px solid #A0A0A0;
  width: 100%;
  border-radius: 20px;
  background-color: white;
  height: 40px;
}

.ownership:hover {
  background-color: #F3F3F3;
}

.ownership button {
  height: 100%;
  cursor: pointer;
  color: #323232;
  flex-grow: 1;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 14px;
  border-radius: 20px;
  border: 0;
  width: 100%;
}

.budget {
  height: 45px;
  border: 1px solid #A0A0A0;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-grow: 1;
  font-size: 16px;
  font-family: 'Sofia Sans', sans-serif;
  border-radius: 30px;
  position: relative;
}

.budget:hover {
  background-color: #F5F5F5;
}

.budget input {
  border: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  color: #323232;
  padding-left: 20px;
}

.budget input:focus {
  outline: none;
}

input::placeholder {
  font-weight: 300;
}

.currency {
  background-color: #A0A0A0;
  color: white;
  height: 100%;
  width: 30%;
  border: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  font-size: 14px;
  border-radius: 0px 30px 30px 0px;
}

.filter-dropdown-toggle {
  height: 40px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Sofia Sans', sans-serif;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #A0A0A0;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  color: #323232;
}

.filter-dropdown-toggle:hover {
  background-color: #F5F5F5;
}

.filter-dropdown-toggle span{
  margin-left: 20px;
  font-size: 14px;
}

.filter-dropdown-list {
  position: absolute;
  background-color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  top: 50px;
  width: 300px;
  max-height: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
}

.rooms .filter-dropdown-list {
  max-height: 80px;
  width: 80px;
}

.filter-dropdown-item {
  text-align: left;
  cursor: pointer;
  height: auto;
}

.filter-dropdown-item.is-highlighted {
  color: #B19977;
  font-style: italic;
}

.filter-dropdown-item:link {
  text-decoration: none;
  color: #323232;
}

.filter-dropdown-item:visited {
  text-decoration: none;
  color: #323232;
}

.filter-dropdown-item:hover {
  color: #B19977;
  font-style: italic;
}

.cta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 500px;
  gap: 10px;
  position: fixed;
  bottom: 0;
  height: 80px;
  border-top: 1px solid #e3e2e2;
  align-items: center;
  background-color: white;
}

.cta button {
  border-radius: 20px;

}

.filter-container .apply {
  background-color: #B19977;
  border: 0;
  color: #FFFFFF;
  margin-right: 50px;
}

.filter-container .apply:hover {
  background-color: #997C54;
}

.reset {
  margin-left: 50px;
}

.reset:hover {
  background-color: #F5F5F5;
}

.fs-filter-tags-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  white-space: pre-line;
  max-width: 100%;
}

.fs-filter-tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.fs-tags {
  display: flex;
  gap: 10px;
  flex-wrap: nowrap;
  flex-wrap: wrap;
}

.fs-tag {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #F5F5F5;
  gap: 10px;
  height: 40px;
  padding: 0px 10px 0px 10px;
  white-space: nowrap;
  border-radius: 20px;
  cursor: pointer;
}

.fs-tag img {
  height: 10px;
}

.fs-tag-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.search-region {
  width: 100%;
  height: 100%;
  color: black;
  font-size: 14px;
  text-align: center;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 20px;
  border: 0;
}

.search-region:focus {
  outline: none;
}

.search-region:hover {
  background-color: #F5F5F5;
}

.filter-dropdown-item.everywhere-style {
  font-weight: bold;
  border-bottom: 1px solid rgb(220, 218, 218);
  padding-bottom: 10px;
}

.clear-input {
  cursor: pointer;
  height: 100%;
  padding: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 0px 20px 0px 20px;
  top: 0;
  right: 0;
}

.clear-input img{
  height: 12px;
}

.rooms {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10px;
}

.select-all-contracts {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.ownership button:disabled {
    background-color: #ffffff;
    color: #666666;
    cursor: not-allowed;
}

.ownership.disabled {
    background-color: #ffffff;
}

.ownership.disabled::hover {
    background-color: #ffffff;
}

@media (max-width: 768px) {

  .close-toggle {
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: 35px;
    padding: 0 15px 0 15px;
    margin-bottom: 20px;
  }

  .filter-sidebar {
    width: 100vw;
    height: auto;
    padding: 0;
    margin-top: 70px;
    display: flex;
    justify-content: center;
  }

  .sidebar-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    justify-items: center;
    padding: 50px 20px 200px 20px;
    gap: 5px;
  }

  .filter-title {
    font-size: 16px;
  }

  .filter-option {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    flex-direction: column;
    gap: 8px;
  }

  .filter-container {
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .filter-container button {
    height: 35px;
    font-size: 14px;
  }

  .budget {
    height: 35px;
    font-size: 12px;
  }

  .budget input {
    border: 0;
    height: 100%;
    width: 70%;
    background-color: transparent;
    padding-left: 19px;
    font-size: 12px;
  }

  .cta {
    z-index: 5;
    width: 100vw;
  }

  .cta .filter-container {
    width: 100%;
  }

  .ownership button {
    height: 100%;
  }

  .filter-container .apply {
    margin-right: 20px;
  }

  .reset {
    margin-left: 20px;
  }

  .select-all-contracts {
    font-size: 14px;
  }

  .rooms {
    font-size: 14px;
  }

  .filter-dropdown-list {
    top: auto;
    bottom: 0;
  }

}

</style>
