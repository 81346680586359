import { createRouter, createWebHistory } from 'vue-router';
import SearchProperty from '@/pages/SearchProperty.vue';
import HomePage from '@/pages/HomePage.vue';
import PropertyDetailPage from '@/pages/PropertyDetailPage.vue';
import ContactFormPage from '@/pages/ContactFormPage.vue';
import LoginPage from '@/pages/LoginPage.vue';
//import { getAuth, onAuthStateChanged } from 'firebase/auth';
import authState from '../authState'; // Import the global auth state


const routes = [

      {
        path: '/',
        component: HomePage
      },

      {
        path: '/login',
        name: 'login',
        component: LoginPage,
      },

      {
        path: '/search',
        name: 'search',
        component: SearchProperty,
        props: route => ({ query: route.query.filter }),
        meta: { requiresAuth: false }
      },

      {
        path: '/listing/:listingId',
        name: 'listing',
        component: PropertyDetailPage,
        meta: { requiresAuth: false }
      },

      {
        path: '/contact',
        name: 'contact',
        component: ContactFormPage,
        meta: { requiresAuth: false }
      },
      
    ];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        // Using setTimeout to ensure the element is rendered
        setTimeout(() => {
          if (document.querySelector(to.hash)) {
            resolve({
              el: to.hash,
              behavior: 'smooth',
            });
          } else {
            resolve({ top: 0, behavior: 'smooth' });
          }
        }, 500); // Add delay
      });
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: 'smooth' };
    }
  },
});

// Global navigation guard
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !authState.user) {
      next('/login');
  } else {
    // Proceed as normal
    next();
  }
});

export default router;
