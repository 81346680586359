<template>
    <LoginComponent />
</template>

<script>
import LoginComponent from '@/components/LoginComponent.vue';

export default {
  name: 'LoginPage',
  components: {
    LoginComponent,
  }
};
</script>

<style>
</style>
