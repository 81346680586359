<template>
  <div class="login-page">
    <div class="login-form">
      <div class="login-title">Contact admin to obtain credential</div>
      <input id="email" v-model="email" type="email" placeholder="Enter email" />
      <input id="password" v-model="password" type="password" placeholder="Enter password" />
      <button @click="loginWithEmail" id="login">Login with Email</button>
    </div>
    <p v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import { auth, signInAnonymously, signInWithEmailAndPassword } from '@/firebase.js';

export default {
  data() {
    return {
      email: '',
      password: '',
      error: null,
    };
  },
  methods: {
    loginWithEmail() {
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          const route = localStorage.getItem('preLoginRoute') || '/';
          this.$router.push({ path: route });
          localStorage.removeItem('preLoginRoute');
        })
        .catch((error) => {
          this.error = error.message;
        });
    },
    loginAnonymously() {
      signInAnonymously(auth)
        .then(() => {
          // Signed in anonymously
          const route = localStorage.getItem('preLoginRoute') || '/';
          this.$router.push({ path: route });
          localStorage.removeItem('preLoginRoute');
          })
        .catch((error) => {
          this.error = error.message;
        });
    },
  },
};
</script>

<style>

.login-page {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: auto;
  flex: 1;
  font-family: 'Sofia Sans', sans-serif;
}

.login-form {
  display: flex;
  flex-direction: column;
  margin: 100px auto auto auto;
  font-size: 16px;
  gap: 10px;
}

.login-form input{
  height: 20px;
  padding: 15px;
  font-family: 'Sofia Sans', sans-serif;
  font-size: 16px;
  border-radius: 20px;
  border: 1px solid #A0A0A0;
}

.login-title {
  font-size: 30px;
  padding-bottom: 20px;
}

#login {
  margin-top: 20px;
  background-color: #B19977;
  color: white;
  cursor: pointer;
  font-size: 18px;
  border: 0;
  height: 50px;
  font-family: 'Sofia Sans', sans-serif;
  border-radius: 20px;
}

</style>