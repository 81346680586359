<template>
  <ContactForm />
</template>

<script>
import ContactForm from '@/components/ContactForm.vue';

export default {
  name: 'ContactFormPage',
  components: {
    ContactForm,
  }
};
</script>

<style>
</style>
