<template>
    <div class="photo-zoom">
        <div class="photo-zoom-wrapper">
            <div class="header">
                <div class="close" @click="closePhotoZoom">Close</div>
                <div class="count">{{ activeImageIndex + 1 }} / {{ totalImages }}</div>
            </div>
            <div class="image-container" ref="imageContainer">
                <img v-lazy="images[activeImageIndex]" :alt="`Image ${index}`" />
                <div class="image-navigation-container">
                    <button class="arrow-left" @click.stop="previousImage">
                        <img src="../assets/chevron-left.svg" alt="previousImage">
                    </button>
                    <button class="arrow-right" @click.stop="nextImage">
                        <img src="../assets/chevron-right.svg" alt="nextImage">
                    </button>
                </div>
            </div>
        </div>
    </div>
  </template>  

<script>
import { mapState } from 'vuex';
import Hammer from 'hammerjs';

export default {

    props: {
        images: Array,
    },

    data() {
        return {
        isCurrencyDropdownOpen: false,
        activeImageIndex: 0,
        }
    },

    watch: {
        isVisible(newVal) {
            this.$emit('update:isVisible', newVal);
        }
    },

    mounted() {
        this.$nextTick(() => {
              this.initSwipe();
          });
        document.addEventListener('keydown', this.handleKeydown);
    },

    beforeUnmount() {
        document.removeEventListener('keydown', this.handleKeydown);
    },

    computed: {
        ...mapState({
            images: state => state.listingImages,
        }),

        totalImages() {
            return this.images.length;
        }
    },

    methods: {
        closePhotoZoom() {
            this.$emit('close');
        },

        previousImage() {
            if (this.activeImageIndex > 0) {
                this.activeImageIndex -= 1;
            } else {
                this.activeImageIndex = this.images.length - 1;
            }
            },

        nextImage() {
            if (this.activeImageIndex < this.images.length - 1) {
                    this.activeImageIndex += 1;
                } else {
                    this.activeImageIndex = 0;
                }
            },

        handleKeydown(e) {
            if (e.key === 'ArrowRight') {
                this.nextImage()
            } else if (e.key === 'ArrowLeft') {
                this.previousImage()
            } else if (e.key === 'Escape') {
                this.closePhotoZoom()
            }
        },

        initSwipe() {
            const imageContainer = this.$refs.imageContainer;
            if (imageContainer instanceof HTMLElement) {
                const mc = new Hammer(imageContainer);
                mc.on("swipeleft", () => this.nextImage());
                mc.on("swiperight", () => this.previousImage());
            } else {
                console.error("Image container not found or is not a DOM element");
            }
        }
    }

}
</script>

<style scoped>

.photo-zoom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  z-index: 2000;
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center;
}

.photo-zoom-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.header {
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
}

.close {
    cursor: pointer;
    z-index: 3000;
}

.image-container {
  display: flex;
  width: 100%;
  justify-content: center;
  height: auto;
  position: relative;
}

.image-container img{
    width: 100%;
    height: auto;
    object-fit: cover;
    justify-content: space-between;
    margin: auto;
}

.image-container img[lazy="loading"] {
    height: 200px;
  }

.image-navigation-container {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    width: 98%;
    align-items: center;
    position: absolute;
    top: 0;
}

.image-navigation-container button {
    width: 30px;
    height: 30px;
    background-color: rgba(160, 160, 160, 0.6);
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0px 15px 0px 15px;
}

.image-navigation-container button img{
    height: 16px;
    margin: auto;
}

.image-navigation-container button:hover {
    background-color: rgba(107, 106, 106, 0.6);
}
</style>