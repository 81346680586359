<template>
  <PropertyDetail />
</template>

<script>
import PropertyDetail from '@/components/PropertyDetail.vue';

export default {
  name: 'PropertyDetailPage',
  components: {
    PropertyDetail
  },
  mounted() {
    this.$nextTick(() => {
      window.scrollTo({ top: document.querySelector('.real-estate-list').offsetTop, behavior: 'smooth' });
    });
  }
};
</script>

<style>

</style>
