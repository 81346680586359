<template>
  <div class="mobile-message">
    <p>Our interactive map is best experienced on a desktop or larger screen. However, you can still browse properties!</p>
    <a id="browse" href="/search">Browse Properties</a>
  </div>
</template>

  
<script>
export default {
  name: 'HomePageMobile'
};
</script>
  
<style>
  .mobile-message {
    text-align: center;
    margin: 30px;
    font-size: 18px;
    color: #333;
    gap: 30px;
    display: flex;
    flex-direction: column;
  }

  #browse {
    background-color: #B19977;
    color: white;
    cursor: pointer;
    font-size: 18px;
    border: 0;
    height: 50px;
    font-family: 'Sofia Sans', sans-serif;
    border-radius: 20px;
    cursor: pointer;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  #browse:hover {
    background-color: #997C54;
  }

  #browse:link {
    text-decoration: none;
  }

  #browse:visited {
    text-decoration: none;
  }
</style>
  