<template>
    <div class="right-overlay" :class="{ hidden: isHidden }">
        <div class="toggle-overlay">
            <div class="toggle-wrapper" @click="toggleSidebar">
                <img id='toggle' src="../assets/toggle.svg">
                <img class='triangle' src="../assets/triangle.svg" :class="{ hidden: isHidden }">
                <span id='toggle-text'>{{ isHidden ? 'Show' : 'Hide' }}</span>
            </div>
        </div>
        <div class="map-story" ref="scrollContainer" :class="{ hidden: isHidden }">
            <div class="expander"
                @touchstart="startTouch"
                @touchmove="moveTouch"
                @touchend="endTouch"
                >
                <span class=expander-line></span>
            </div>
            <div class="story-container">
                <div class="page-cover">
                    <div class="cover-title">
                        Dive into Bali's treasures!
                    </div>
                    <div class="cover-subtitle">
                        Click an area on the map or scroll down to explore!
                    </div>
                </div>
                <div 
                v-for="(feature, index) in features" 
                :key="feature.properties.name" 
                class="story" 
                :ref="'story-' + index"
                @click="scrollToStory(feature)"
                >
                    <div class="story-title">
                        {{ feature.properties.name }}
                    </div>
                    <div class="story-description">
                        {{ feature.properties.intro }}
                    </div>
                    <div class="story-properties">
                        <div class="spec-section">
                            <div class="spec-section-name">
                                Properties in this area:
                            </div>
                            <div class="spec-section-container">
                                <div class="property-detail">
                                    <img src="../assets/story-leasehold.svg" />
                                    Lease Hold: {{ feature.properties.number_of_lease_hold }}
                                </div>
                                <div class="property-detail">
                                    <img src="../assets/story-freehold.svg" />
                                    Free Hold: {{ feature.properties.number_of_free_hold }}
                                </div>
                            </div>
                        </div>
                        <div class="spec-section">
                            <div class="spec-section-name">
                                Price per SQM:
                            </div>
                            <div class="spec-section-container">
                                <div class="property-detail">
                                    <img src="../assets/story-lowest.svg" />
                                    Lowest: {{ selectedCurrency }} {{ formattedPrice(feature, 'lowest_price_per_sqm') }}
                                </div>
                                <div class="property-detail">
                                    <img src="../assets/story-median.svg" />
                                    Median: {{ selectedCurrency }} {{ formattedPrice(feature) }}
                                </div>
                                <div class="property-detail">
                                    <img src="../assets/story-highest.svg" />
                                    Highest: {{ selectedCurrency }} {{ formattedPrice(feature, 'highest_price_per_sqm') }}
                                </div>
                            </div>
                        </div>
                        <div class="spec-section">
                            <div class="spec-section-name">
                                Coming soon:
                            </div>
                            <div class="spec-section-container">
                                <div class="property-detail">
                                    <img src="../assets/story-population.svg" />
                                    Population density
                                </div>
                                <div class="property-detail">
                                    <img src="../assets/story-bnb.svg" />
                                    BnB rental price
                                </div>
                            </div>
                        </div>
                        <div class="property-lowest-price" @click="goToSearch(feature, region)">
                            See properties on this area
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="story-navigation">
            <div class="story-navigation-wrapper" ref="navigationContainerParent">
                <div class="navigation-container" ref="navigationContainer">
                    <div 
                    v-for="(feature, index) in features" 
                    :key="feature.properties.name" 
                    >
                        <div src="../assets/circle.svg" 
                        class="navigation" 
                        :ref="'navigation-' + index"
                        @click="scrollToStory(feature)"
                        :title="feature.properties.name"
                        >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import regions from '@/assets/regions.json';

export default{
    name: 'MapStory',

    data() {
        return {
            markerClicked: false,
            selectedArea: {
                name: '',
                numberOfProperties: '',
                pricePerSqmIdr: '',
                pricePerSqmEur: '',
                pricePerSqmUsd: '',
                pricePerSqmAud: '',
                pricePerSqmSgd: '',
                pricePerSqmGbp: '',
                pricePerSqmBtc: '',
                highestPricePerSqmIdr: '',
                highestPricePerSqmEur: '',
                highestPricePerSqmUsd: '',
                highestPricePerSqmAud: '',
                highestPricePerSqmSgd: '',
                highestPricePerSqmGbp: '',
                highestPricePerSqmBtc: '',
                lowestPricePerSqmIdr: '',
                lowestPricePerSqmEur: '',
                lowestPricePerSqmUsd: '',
                lowestPricePerSqmAud: '',
                lowestPricePerSqmSgd: '',
                lowestPricePerSqmGbp: '',
                lowestPricePerSqmBtc: '',
                intro: '',
            },
            features: [],
            observer: null,
            isHidden: false,
            activeImageIndex: 0,
            selectedCurrency: 'EUR',
            touchStartY: 0,
            touchEndY: 0,
        }
    },

    methods: {

        toggleSidebar() {
            this.isHidden = !this.isHidden;
            },

        loadFeatures() {
            this.features = regions.features;
            console.log('storeRegions: '+this.features)
            this.$store.commit('storeRegions', this.features);
            },

        goToSearch(feature, type='region') {
      
            this.$store.dispatch('applyFilter', { filterName: 'currency', value: this.selectedCurrency });
            
            let nextPath;
            let query;

            // Go to the search page
            if (type === 'all') {

                // Construct search query
                query = { 
                    currency: this.selectedCurrency
                } 

                // Save next route to redirect after login
                nextPath = this.$router.resolve({
                    path: '/search',
                    query: query
                    }).href;

            } else {
                this.$store.dispatch('saveSearchResultInfo', { name: 'regionName', value: feature.properties.name });
                this.$store.dispatch('applyFilter', { filterName: 'region', value: feature.properties.name });

                // Construct search query
                query = { 
                    region: feature.properties.name,
                    currency: this.selectedCurrency
                }

                // Save next route to redirect after login
                nextPath = this.$router.resolve({
                    path: '/search',
                    query: query
                }).href;
            }

            // Save nextPath to local storage
            localStorage.setItem('preLoginRoute', nextPath);

            // Open search page with query
            this.$router.push({ 
                path: '/search',
                query: query
                });

            // Execute search operation and fetch data
            this.$store.dispatch('fetchData');
            
        
        },

        formattedPrice(region, priceType = 'price_per_sqm') {
            let price;
            let formatted;

            // Construct the property name dynamically based on the selected currency and priceType
            const propertyKey = `${priceType}_${this.selectedCurrency.toLowerCase()}`;

            // Get the price from the region properties
            price = region.properties[propertyKey];

            // Format the price based on the selected currency
            if (this.selectedCurrency === 'IDR' || this.selectedCurrency === 'EUR' || this.selectedCurrency === 'USD' ||
                this.selectedCurrency === 'AUD' || this.selectedCurrency === 'SGD' || this.selectedCurrency === 'GBP') {
                if (price >= 1000000) {
                    formatted = `${(price / 1000000).toFixed(2)}m`;
                } else formatted = new Intl.NumberFormat('en-US').format(price);
            } else if (this.selectedCurrency === 'BTC') {
                formatted = price;
            } else {
                return "Currency not supported";
            }

            return formatted;
        },

        onScroll() {
            clearTimeout(this.scrollTimeout); 
            this.scrollTimeout = setTimeout(() => {
                this.features.forEach((feature, index) => {
                const storyElement = this.$refs['story-' + index][0];
                if (this.isElementInViewport(storyElement)) {
                    const storyTitle = storyElement.querySelector('.story-title').textContent;
                    const matchedFeature = this.features.find(f => f.properties.name === storyTitle);
                    if (matchedFeature) {
                    this.$store.commit('setSelectedArea', matchedFeature);
                    }
                }
                });
            }, 200);
        },

        isElementInViewport(el) {
            if (!el) return false;
            const rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
            },

        isElementInParentViewport(el, parent) {
            const parentRect = parent.getBoundingClientRect();
            const rect = el.getBoundingClientRect();

            return (
                rect.top >= parentRect.top &&
                rect.left >= parentRect.left &&
                rect.bottom <= parentRect.bottom &&
                rect.right <= parentRect.right
            );
        },

        previousImage(region) {
            if (this.activeImageIndex > 0) {
                this.activeImageIndex -= 1;
            } else {
                this.activeImageIndex = region.properties.images.length - 1;
            }
        },

        nextImage(region) {
            if (this.activeImageIndex < region.properties.images.length - 1) {
                this.activeImageIndex += 1;
            } else {
                this.activeImageIndex = 0;
            }
        },

        resetNavigationColors() {
            this.features.forEach((feature, index) => {
                const navigationElement = this.$refs['navigation-' + index][0];
                if (navigationElement) {
                    navigationElement.classList.remove('selected')
                }
            });
            },

        scrollToStory(feature) {
            this.$store.commit('setSelectedArea', feature);

        },

        scrollElementIntoView(element, parent) {
            if (element && parent) {
                // Get the element's offset top relative to the parent
                const offsetTop = element.offsetTop;

                console.log("Element:", element);
                console.log("Parent:", parent);
                console.log("Offset top element: " + offsetTop);
                console.log("Offset top parent: " + parent.offsetTop);

                // Scroll the parent to the element's position
                parent.scrollTo({
                top: offsetTop,
                //behavior: 'smooth'
                });
            }
        },

        startTouch(event) {
            this.touchStartY = event.touches[0].clientY;
            },

        moveTouch(event) {
            this.touchEndY = event.touches[0].clientY;
        },

        endTouch() {
            if (this.touchEndY > this.touchStartY - 50) {
                this.isHidden = true;
            } else if (this.touchEndY < this.touchStartY + 50) {
                this.isHidden = false;
            }
        },

    },

    beforeUnmount() {
        const scrollContainer = this.$refs.scrollContainer;
            if (scrollContainer) {
            scrollContainer.removeEventListener('scroll', this.onScroll);
            }
    },

    mounted() {
        const scrollContainer = this.$refs.scrollContainer;
            if (scrollContainer) {
            scrollContainer.addEventListener('scroll', this.onScroll);
            }
        this.loadFeatures();
        
    },

    watch: {
        '$store.state.selectedArea': {
            immediate: true,
            handler(newVal) {
            if (newVal && newVal.properties) {
                this.isHidden = false;
                this.$nextTick(() => {
                let storyElement;
                let navigationElement;
                const storyParent = this.$refs['scrollContainer']
                const navigationParent = this.$refs['navigationContainer']

                // Ensure the parent elements are scrollable
                console.log("Story Parent:", storyParent);
                console.log("Navigation Parent:", navigationParent);

                // Find the story and navigation elements
                for (let i = 0; i < this.features.length; i++) {
                    const storyElements = this.$refs['story-' + i];
                    if (storyElements && storyElements.length > 0) {
                    const element = storyElements[0];
                    const storyTitle = element.querySelector('.story-title').textContent;
                    if (storyTitle === newVal.properties.name) {
                        this.resetNavigationColors();
                        storyElement = element;
                        navigationElement = this.$refs['navigation-' + i][0];
                        navigationElement.classList.add('selected');
                        break;
                    }
                    }
                }

                if (storyElement && navigationElement) {
                    // Scroll navigation element into view
                    console.log('You are here');
                    console.log("Navigation Element:", navigationElement);
                    console.log("Navigation Parent:", navigationParent);

                    this.scrollElementIntoView(navigationElement, navigationParent);

                    // Ensure the navigation scroll is completed before scrolling the story element
                    this.$nextTick(() => {
                    this.scrollElementIntoView(storyElement.querySelector('.story-title'), storyParent);
                    });
                }
                });
            }
            }
        },


        '$store.state.selectedCurrency': {
            immediate: true,
            handler(newVal) {
                this.selectedCurrency = newVal;
            }
        },
    }

}
</script>

<style scoped>

.right-overlay {
    position: fixed;
    height: 100vh;
    width: 600px;
    display: flex;
    top: 0;
    right: 0;
    transition: transform 0.3s ease;
}

.map-story {
    width: 600px;
    position: fixed;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
    flex-grow: 1;
    top: 60px;
    bottom: 60px;
    height: 90vh;
    max-height: 90vh;
    overflow: scroll;
}

.story-container {
    display: flex;
    flex-direction: column;
}

.page-cover {
    height: 50vh;
    width: 80%;
    padding: 70px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.cover-title {
    font-size: 48px;
    color: #323232;
    font-weight: 800;
}

.cover-subtitle {
    font-size: 20px;
    font-weight: 400;
    color: #7f7e7e;
}

.story {
    height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 70px;
    padding-right: 70px;
    cursor: pointer;
}

.story:hover {
    background-color: rgba(235, 235, 235, 0.8);
}

.story-title {
    font-size: 38px;
    color: #323232;
    font-weight: 800;
    padding-top: 20px;
}

.story-description {
    font-size: 16px;
    font-weight: 400;
    color: #7f7e7e;
}

.story-properties {
    display: flex;
    flex-direction: column;
    color: #7f7e7e;
    gap: 20px;
}

.property-detail {
    font-size: 14px;
}

.property-detail img {
    height: 14px;
    width: auto;
}

.property-lowest-price {
    margin-top: 20px;
    color: #B19977;
    font-size: 20px;
    font-style: italic;
    cursor: pointer;
}

.property_lowest_price:hover {
    text-decoration: underline;
}

.toggle-overlay {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
    writing-mode: vertical-rl;
    font-size: 16px;
    color: white;
}

.toggle-wrapper {
    position: absolute;
    display: flex;
    width: 60px;
    height: 100px;
    justify-content: center;
    left: -58px;
}

#toggle {
    height: 100px;
    width: auto;
}

.triangle {
    position: absolute;
    left: 9px;
    transition: transform 0.3s ease;
    width: 6px;
    height: auto;
}

#toggle-text {
    position: absolute;
    left: 28px;
    transform: rotate(180deg);
}

.right-overlay.hidden {
  transform: translateX(85%);
  transition: transform 0.3s ease;
}

.triangle.hidden {
    transform: rotate(180deg);
}

.story-image-container {
  overflow: hidden;
  height: 280px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 24px;
}

.story-image-container:hover .overlay {
  background-color: rgba(0, 0, 0, 0.2);
}

.story-navigation {
    width: 50px;
    position: absolute;
    right: 0;
    height: 90vh;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.story-navigation-wrapper {
    height: 200px;
    max-height: 200px;
    overflow: scroll;
}

.navigation-container {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    height: 200px;
    overflow: scroll;
    gap: 8px;
    overflow-y: auto;
}

.navigation {
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-color: #B19977;
    border-radius: 100%;
    background-color: #ffffff;
    width: 12px;
    height: 12px;
    position: relative;
    display: inline-block;
}

.navigation:hover {
    background-color: #B19977;
}

.navigation.selected {
    background-color: #B19977;
}

.toggle-overlay-mobile {
    display: none;
}

.expander {
    display: none;
}

.spec-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.spec-section-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

@media (max-width: 768px) {

    .right-overlay {
        width: 100vw;
        height: 35vh;
        position: fixed;
        top: auto;
        bottom: 0;
        z-index: 999;
        border-radius: 20px 20px 0 0;
        background-color: rgba(255, 255, 255);
        align-items: center;
    }

    .right-overlay.hidden {
        transform: translateY(80%);
        transition: transform 0.3s ease;
        }

    .map-story {
        width: 100%;
        top: auto;
        bottom: 0;
        height: 100%;
        max-height: 35vh;
        position: absolute;
        background-color: transparent;
    }

    .page-cover {
        padding-left: 30px;
        padding-right: 30px;
        height: auto;
    }

    .story {
        padding-left: 30px;
        padding-right: 30px;
        height: auto;
    }

    .toggle-overlay {
        display: none;
    }

    .toggle-overlay-mobile {
        height: 100%;
        width: 100%;
        position: relative;
        display: none;
        cursor: pointer;
        font-size: 12px;
        color: white;
        flex-direction: row;
        justify-content: center;
    }

    .toggle-overlay-mobile .toggle-wrapper {
        position: absolute;
        display: flex;
        top: -10%;
        left: auto;
        right: auto;
        width: 60px;
        height: 100px;
    }

    .toggle-overlay-mobile #toggle-text {
        transform: none;
    }

    #toggle {
        height: 30px;
        width: auto;
    }

    #toggle-text {
        left: auto;
        top: 14%;
        font-size: 12px;
    }

    .triangle {
        left: auto;
        top: 6%;
        width: 5px;
        height: auto;
    }

    .cover-title {
        font-size: 32px;
    }

    .story-title {
        font-size: 28px;
    }

    .story {
        font-size: 14px;
        padding-right: 50px;
        padding-bottom: 100px;
    }

    .story-description {
        font-size: 14px;
    }

    .property-detail img {
        height: 16px;
    }

    .story-navigation {
        height: 100%;
        width: 35px;
        display: flex;
        height: 160px;
        margin-top: auto;
        margin-bottom: auto;
    }

    .navigation-container {
        height: 160px;
    }

    .navigation {
        width: 10px;
        height: 10px;
    }

    .expander {
        height: 20px;
        width: 100%;
        display: flex;
        position: fixed;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: transparent;
    }

    .expander-line {
        height: 3px;
        width: 80px;
        background-color: #d8d8d8;
        border-radius: 5px;
        display: flex;
        position: fixed;
    }

    .menu-dropdown {
        font-size: 12px;
    }



}

</style>